import React from "react";
import { useGetCustomerRetention } from "../../../api/endpoints/get-customer-retention";
import { PRIMARY_COLOR } from "../../../utils/colors";
import { BaseProps } from "../../../utils/types";
import { Graph } from "../../ui/Graph";
import { GraphContainer } from "../../ui/GraphContainer";

interface CustomerRetentionGraphProps extends BaseProps {
  colors?: string[];
}
export const CustomerRetentionGraph = ({
  branches,
  from,
  to,
  colors = [PRIMARY_COLOR],
}: CustomerRetentionGraphProps) => {
  const { data, isLoading } = useGetCustomerRetention({
    branches,
    from,
    to,
  });

  const { keys, series } = React.useMemo(() => {
    if (!data || isLoading) {
      return {
        keys: [],
        series: [],
      };
    }

    const sales = data
      .sort((a, b) => a.branch_name.localeCompare(b.branch_name))
      .reduce((acc, item) => {
        if (!acc[item.branch_name]) {
          acc[item.branch_name] = 0;
        }
        acc[item.branch_name] += item.retention_rate;
        return acc;
      }, {} as Record<string, number>);

    return {
      keys: Object.keys(sales),
      series: Object.values(sales),
    };
  }, [data, isLoading]);

  return (
    <GraphContainer>
      <Graph
        key="customer-retention-graph"
        data={{
          xAxis: [{ keys }],
          series: [{ data: series, label: "Retención" }],
        }}
        title={"Tasa de retención de clientes por tienda"}
        colors={colors}
      />
    </GraphContainer>
  );
};
