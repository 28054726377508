import { useGetCustomerStats } from "../../../api/endpoints/get-customer-stats";
import { SECONDARY_COLOR } from "../../../utils/colors";
import { BaseProps } from "../../../utils/types";
import { NumberCard } from "../../ui/NumberCard";
import { CustomerRetentionGraph } from "../CustomerRetentionGraph";
import { CustomerStatsTimeSeries } from "../CustomerStatsTimeSeries";

interface CustomersSummaryProps extends BaseProps {}
export const CustomersSummary = ({
  branches,
  from,
  to,
  totalBranches,
}: CustomersSummaryProps) => {
  const { data: statsData, isLoading: statsIsLoading } = useGetCustomerStats({
    branches,
    from,
    to,
  });
  return (
    <div>
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.total_visits ?? 0}
          title={"Total de visitas en este rango"}
        />
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.unique_visits ?? 0}
          title={"Clientes únicos en este rango"}
        />
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.avg_uses_per_customer.toFixed(2) ?? 0}
          title={"Promedio de usos por cliente"}
        />
        <NumberCard
          isLoading={statsIsLoading}
          amount={statsData?.retention_rate.toFixed(2) ?? 0}
          title={"Tasa de retención"}
          tooltip="Porcentaje de clientes que regresan comparado al mismo periodo de tiempo del mes pasado"
        />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-2">
        <CustomerStatsTimeSeries branches={branches} from={from} to={to} />
      </div>
      <div className="mt-2">
        {totalBranches && totalBranches > 1 && (
          <CustomerRetentionGraph
            branches={branches}
            from={from}
            to={to}
            colors={[SECONDARY_COLOR]}
          />
        )}
      </div>
    </div>
  );
};
