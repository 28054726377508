import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useSetUrlParams = (params: { [key: string]: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    const newSearch = searchParams.toString();
    if (newSearch !== location.search.substring(1)) {
      navigate(
        {
          search: newSearch,
        },
        {
          replace: true,
        }
      );
    }
  }, [params, location, navigate]);
};
