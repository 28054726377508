interface InfoSectionProps {
  content: string;
}

export const InfoSection = ({ content }: InfoSectionProps) => {
  return (
    <div
      style={{
        backgroundColor: "#DFF4FF",
        padding: "5px",
        borderRadius: "3px",
        border: "#004F9F 1px solid",
        color: "#004F9F",
      }}
    >
      {content}
    </div>
  );
};
