import { MachineStatus } from "../../api/endpoints/get-machine-statuses";
import {
  MachineDailyStatus,
  Status,
} from "../../types/machines/machine-statuses";
import { formatDate, toMexicoTimeZone } from "../date";

const getState = (isActive: boolean): Status => {
  return isActive ? "active" : "inactive";
};

export const getDailyStatuses = (
  history: MachineStatus[],
  startDate: string,
  endDate: string
): [MachineDailyStatus[], string] => {
  const dailyStates: MachineDailyStatus[] = [];

  let start = toMexicoTimeZone(startDate);
  let end = toMexicoTimeZone(endDate);

  start.setHours(23, 59, 59, 999);
  end.setHours(23, 59, 59, 999);

  const sortedHistory = history
    .map((entry) => ({
      ...entry,
      occurred_at: toMexicoTimeZone(entry.occurred_at),
    }))
    .sort((a, b) => b.occurred_at.getTime() - a.occurred_at.getTime());

  let currentState = getState(sortedHistory[0]?.is_active ?? false);

  let d = toMexicoTimeZone(start);

  let uptimePercentage = 0;

  while (d <= end) {
    const latestEvent = sortedHistory.filter((h) => {
      const temp = new Date(d);
      temp.setHours(23, 59, 59, 999);
      return h.occurred_at.getTime() <= temp.getTime();
    })[0];

    if (latestEvent) {
      currentState = getState(latestEvent.is_active);
    }

    const dateString = formatDate(d);
    dailyStates.push({ date: dateString, status: currentState });

    d.setDate(d.getDate() + 1); // Move to next day

    if (currentState === "active") {
      uptimePercentage += 1;
    }
  }

  return [
    dailyStates,
    ((uptimePercentage / dailyStates.length) * 100).toFixed(2),
  ];
};
