import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface RevenueSalesFrame {
  time_period: string;
  total_sales: number;
  total_movements: number;
}
interface RevenueTimeSeriesResponse {
  data: RevenueSalesFrame[];
}

export const getRevenueTimeSeries = async ({
  from,
  to,
  branches,
  period,
}: RevenueTimeSeriesProps) => {
  let url = "/api/v1/revenue/timeseries";
  url = addBaseURLParams(url, { from, to, branches });
  if (period) {
    url += `&period=${period}`;
  }
  const response = await ax
    .get<RevenueTimeSeriesResponse>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

interface RevenueTimeSeriesProps extends BaseProps {
  period?: string;
}

export const useRevenueTimeSeries = ({
  branches,
  from,
  to,
  period,
}: RevenueTimeSeriesProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["revenue-timeseries", from, to, branches, period],
    queryFn: () => getRevenueTimeSeries({ branches, from, to, period }),
  });
  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
