import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface CustomerStatsFrame {
  time_period: string;
  visits: number;
}

interface Response {
  data: CustomerStatsFrame[];
}

export const getCustomerStatsTimeSeries = async ({
  branches,
  from,
  to,
  period,
}: GetCustomersStatsTimeSeries) => {
  let url = "/api/v1/customers/stats/timeseries";
  url = addBaseURLParams(url, { from, to, branches });

  if (period) {
    url += `&period=${period}`;
  }

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetCustomersStatsTimeSeries extends BaseProps {
  period?: string;
}
export const useGetCustomerStatsTimeSeries = ({
  branches,
  from,
  to,
  period,
}: GetCustomersStatsTimeSeries) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-customer-stats-timeseries", branches, from, to, period],
    queryFn: () => getCustomerStatsTimeSeries({ branches, from, to, period }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
