import React from "react";
import { useGetMachineStats } from "../../../api/endpoints";
import { calculateDaysBetween } from "../../../utils/date";
import { formatToMXN } from "../../../utils/money";
import { BaseProps } from "../../../utils/types";
import { NumberCard } from "../../ui/NumberCard";
import { MachineStatsByPeriodGraph } from "../MachineStatsByPeriodGraph";

interface MachineSalesSummaryProps extends BaseProps {}

export const MachineSalesSummary = ({
  branches,
  from,
  to,
}: MachineSalesSummaryProps) => {
  const { data, isLoading } = useGetMachineStats({ branches, from, to });

  const { avgUsesPerDay } = React.useMemo(() => {
    if (!data) {
      return { avgUsesPerDay: 0 };
    }

    const usesPerWasher = data.washer_uses / data.total_washers;
    const totalDays = calculateDaysBetween(from, to);
    const avgUsesPerDay = (usesPerWasher / totalDays).toFixed(2);
    return { avgUsesPerDay };
  }, [data, from, to]);

  return (
    <div>
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.washer_sales ?? 0)}
          title={"Ventas Lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.washer_uses ?? 0}
          title={"Usos Lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.dryer_sales ?? 0)}
          title={"Ventas Secadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.dryer_uses ?? 0}
          title={"Usos Secadoras"}
        />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_washers ?? 0}
          title={"Número de lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_dryers ?? 0}
          title={"Número de secadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={avgUsesPerDay}
          title={"Promedio de usos por día"}
        />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-2 gap-5 mb-4">
        <MachineStatsByPeriodGraph
          from={from}
          to={to}
          branches={branches}
          machineIds={[]}
        />
        <MachineStatsByPeriodGraph
          from={from}
          to={to}
          branches={branches}
          machineIds={[]}
          graphType="uses"
        />
      </div>
    </div>
  );
};
