import React from "react";

import { MachineSalesTable } from "../MachineSalesTable/MachineSalesTable";
import { MachineDetail } from "../MachineDetail";
import { useBranches } from "../../../hooks/use-branches";
import { MachineSalesSummary } from "../MachineSalesSummary";
import { BasicTabs } from "../../ui/Tabs";
import { useTimeFrame } from "../../../hooks/time-frame/use-time-frame";

export const PageMachines = () => {
  const { from, to } = useTimeFrame();
  const { branches } = useBranches();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(0);
  const [machineID, setMachineID] = React.useState<number | undefined>(
    undefined
  );

  return (
    <div className="w-auto">
      <BasicTabs
        tabs={[
          {
            label: "Resumen",
            content: (
              <MachineSalesSummary branches={branches} from={from} to={to} />
            ),
          },
          {
            label: "Máquinas",
            content: (
              <MachineSalesTable
                branches={branches}
                from={from}
                to={to}
                onRowClick={(params) => {
                  setOpenDrawer(true);
                  setMachineID(params.row.id);
                }}
              />
            ),
          },
        ]}
        value={tab}
        onValueChange={(_, tab) => setTab(tab)}
      />
      <MachineDetail
        id={machineID}
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
        branches={branches}
        from={from}
        to={to}
      />
    </div>
  );
};
