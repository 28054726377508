import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface RetentionFrame {
  branch_id: number;
  branch_name: string;
  retention_rate: number;
}

interface CustomerRetentionResponse {
  data: RetentionFrame[];
}

export const getCustomerRetention = async ({
  branches,
  from,
  to,
}: BaseProps) => {
  let url = "/api/v1/customers/retention";
  url = addBaseURLParams(url, { from, to, branches });

  const response = await ax
    .get<CustomerRetentionResponse>(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });

  return response;
};

interface GetCustomerRetentionRate extends BaseProps {}
export const useGetCustomerRetention = ({
  branches,
  from,
  to,
}: GetCustomerRetentionRate) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-customer-retention", branches, from, to],
    queryFn: () => getCustomerRetention({ branches, from, to }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }
  return { data: data.data, isLoading };
};
