import React from "react";
import { NumberCard } from "../../ui/NumberCard";
import { MachineStatsByIDResponse } from "../../../api/endpoints/get-machine-stats-by-id";
import { MachineStatus } from "../../../api/endpoints/get-machine-statuses";
import { BaseProps } from "../../../utils/types";
import { formatToMXN } from "../../../utils/money";
import { getDailyStatuses } from "../../../utils/machines/machine-statuses";
import { Tracker } from "@tremor/react";
import { InfoSection } from "../../ui/InfoSection";

interface MachineDetailSummaryProps extends BaseProps {
  id?: number;
  data?: MachineStatsByIDResponse["data"];
  statuses: MachineStatus[] | undefined;
  isLoading: boolean;
  isLoadingStatuses: boolean;
}
export const MachineDetailSummary = ({
  data,
  statuses,
  isLoading,
  isLoadingStatuses,
  from,
  to,
}: MachineDetailSummaryProps) => {
  const { dailyStatuses, uptimePercentage } = React.useMemo(() => {
    if (!statuses || isLoadingStatuses) {
      return {
        dailyStatuses: [],
        uptimePercentage: 0,
      };
    }
    const [daily, uptime] = getDailyStatuses(statuses, from, to);
    return {
      dailyStatuses: daily.map((d) => ({
        key: d.date,
        color: d.status === "active" ? "green" : "red",
        tooltip: d.date,
      })),
      uptimePercentage: uptime,
    };
  }, [statuses, from, to]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <NumberCard
          isLoading={isLoading}
          title={"Sucursal"}
          amount={data?.branch_name ?? ""}
        />
        <NumberCard
          isLoading={isLoading}
          title={"Tipo de máquina"}
          amount={data?.machine_type_name ?? ""}
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.total_sales ?? 0)}
          title={"Ventas"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_uses ?? 0}
          title="Usos"
        />
      </div>
      <div className="mt-4">
        <div className="font-bold">Tiempo operativo</div>
        {dailyStatuses.length < 32 ? (
          <Tracker data={dailyStatuses} />
        ) : (
          <InfoSection content="Escoga un rango de tiempo igual o menor a 1 mes para visualizar la gráfica" />
        )}
        <div className="grid grid-cols-2 gap-2 mt-4">
          <NumberCard
            isLoading={isLoading}
            amount={uptimePercentage}
            title="% de tiempo operativo"
          />
        </div>
      </div>
    </div>
  );
};
