import React from "react";
import { useGetMachinesAggregatedSalesByPeriod } from "../../../api/endpoints/get-machines-aggregated-sales-by-period";
import { BaseProps, Period } from "../../../utils/types";
import { Graph } from "../../ui/Graph";
import { GraphContainer } from "../../ui/GraphContainer";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../../utils/colors";
import { formatToMXN } from "../../../utils/money";
import { PeriodSelector } from "../../ui/PeriodSelector";
import { getXAxisValueFormatterForPeriod } from "../../../utils/charts";

type GraphType = "sales" | "uses";

const DEFAULT_COLORS = [PRIMARY_COLOR, SECONDARY_COLOR];

const getTitle = (period: string, graphType: GraphType) => {
  switch (period) {
    case "day":
      return graphType === "sales" ? "Ventas por día" : "Usos por día";
    case "day_of_week":
      return graphType === "sales"
        ? "Ventas por día de la semana"
        : "Usos por día de la semana";
    case "month":
      return graphType === "sales" ? "Ventas por mes" : "Usos por mes";
    case "hour":
      return graphType === "sales" ? "Ventas por" : "Usos por";
    default:
      return "";
  }
};

interface MachineStatsByPeriodGraphProps extends BaseProps {
  machineIds: number[];
  width?: number;
  graphType?: GraphType;
  colors?: string[];
}
export const MachineStatsByPeriodGraph = ({
  branches,
  from,
  to,
  machineIds,
  width,
  graphType = "sales",
  colors = DEFAULT_COLORS,
}: MachineStatsByPeriodGraphProps) => {
  const [period, setPeriod] = React.useState<Period>("day");
  const { data, isLoading } = useGetMachinesAggregatedSalesByPeriod({
    branches: branches,
    from: from,
    to: to,
    machineIds,
    period,
  });

  const { washerKeys, washerSeries, dryerKeys, dryerSeries } =
    React.useMemo(() => {
      if (!data || isLoading) {
        return {
          washerKeys: [],
          washerSeries: [],
          dryerKeys: [],
          dryerSeries: [],
        };
      }

      const groupedSales = data.reduce(
        (acc, item) => {
          const key = item.machine_type === "lavadora" ? "washer" : "dryer";
          if (!acc[key][item.time_period]) {
            acc[key][item.time_period] = 0;
          }
          if (graphType === "uses") {
            acc[key][item.time_period] += item.total_movements;
          } else {
            acc[key][item.time_period] += item.total_sales;
          }
          return acc;
        },
        {
          washer: {} as Record<string, number>,
          dryer: {} as Record<string, number>,
        }
      );

      return {
        washerKeys: Object.keys(groupedSales.washer),
        washerSeries: Object.values(groupedSales.washer),
        dryerKeys: Object.keys(groupedSales.dryer),
        dryerSeries: Object.values(groupedSales.dryer),
      };
    }, [data, isLoading]);
  return (
    <GraphContainer className={"mt-4"}>
      <Graph
        key="machine-sales-by-period-graph"
        data={{
          xAxis: [{ keys: washerKeys }, { keys: dryerKeys }],
          series: [
            washerSeries.length > 0 && {
              data: washerSeries,
              label: "Lavadoras",
            },
            dryerSeries.length > 0 && { data: dryerSeries, label: "Secadoras" },
          ].filter(Boolean) as any,
        }}
        title={getTitle(period, graphType)}
        rightTitleComponent={
          <PeriodSelector period={period} setPeriod={setPeriod} />
        }
        colors={colors}
        width={width}
        xAxisValueFormatter={getXAxisValueFormatterForPeriod(period)}
        seriesValueFormatter={
          graphType === "sales" ? (value) => formatToMXN(value) : undefined
        }
      />
    </GraphContainer>
  );
};
