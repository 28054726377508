import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="w-auto"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 2 }}>{children}</Box>}
    </div>
  );
}

interface BasicTabsProps {
  tabs: {
    content: React.ReactNode;
    label: string;
  }[];
  value: number;
  onValueChange: (event: React.SyntheticEvent, newValue: number) => void;
}
export const BasicTabs = ({ tabs, value, onValueChange }: BasicTabsProps) => {
  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs value={value} onChange={onValueChange} aria-label="basic-tabs">
          {tabs.map((tab, index) => {
            return <Tab label={tab.label} tabIndex={index} />;
          })}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => {
        return (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.content}
          </CustomTabPanel>
        );
      })}
    </div>
  );
};
