import { formatDate, getDayOfTheWeekFromNumber, getMonth } from "./date";
import { Period } from "./types";

export const SLOT_PROPS = {
  loadingOverlay: { message: "Cargando datos..." },
  noDataOverlay: {
    message: "No hay datos disponibles para los párametros seleccionados",
  },
};

export const getXAxisValueFormatterForPeriod = (period: Period) => {
  switch (period) {
    case "day":
      return (value: string) => formatDate(value);
    case "month":
      return (value: string) => getMonth(value);
    case "day_of_week":
      return (value: string) => getDayOfTheWeekFromNumber(value);
    case "hour":
    default:
      return (value: string) => value;
  }
};
